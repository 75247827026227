import React, { Component } from 'react'
import { QuestionTypeKeys , QuestionTypeKeysWithAQ} from '../constants';
import SingleQuestion from './singleQuestion';
import { Checkbox } from 'primereact/checkbox';
import { InputSwitch } from 'primereact/inputswitch';
export default class QuestionInTable extends Component {
    constructor(props) {
        super(props);
    }


    optionsRender = (options) => {
        return <div className='' style={{ width: '100%', display: 'inline-block' }}>
            {options && options.length ? options.map((option, oIndex) => <div className={`option-w ${oIndex % 2 == 0 ? 'sp-clearFix' : ''}`} key={option.id}>
                <SingleQuestion optionN={option.id} mathJaxValue={option.value} attachment={option.imageUrl} />
            </div>) : <></>}
        </div>

    }

    renderFullQuestion = (question) => {
        switch (question.questionType) {
            case QuestionTypeKeysWithAQ.SCCT:
            case QuestionTypeKeysWithAQ.MCCT:
            case QuestionTypeKeysWithAQ.PQS:
            case QuestionTypeKeysWithAQ.PQM:
                return <>
                    {
                        question.paragraph && <>
                            <p className='question-label'>Paragraph</p>
                            <SingleQuestion mathJaxValue={question.paragraph} attachment={question.paragraphUrl} />
                        </>
                    }


                    <p className='question-label'>Question</p>
                    <SingleQuestion mathJaxValue={question.question} attachment={question.attachmentUrl} />
                    <p className='question-label'>Options</p>
                    {this.optionsRender(question.options)}
                    <p className='ma-no-pm'> <span className='question-label'>Key</span><span className='question-d  key-value'>
                        {(question.questionType == QuestionTypeKeysWithAQ.SCCT || question.questionType == QuestionTypeKeysWithAQ.PQS) ? question.key : question.key.join(', ')}
                    </span></p>
                </>

            case QuestionTypeKeysWithAQ.NUM:
            case QuestionTypeKeysWithAQ.INT:
                return <>
                    <p className='question-label'>Question</p>
                    <SingleQuestion mathJaxValue={question.question} attachment={question.attachmentUrl} />
                    <p className='ma-no-pm'> <span className='question-label'>Key</span><span className='question-d  key-value'>
                        {question.key}
                    </span></p>
                </>
            case QuestionTypeKeysWithAQ.FB:
                return <>
                    <p className='question-label'>Question</p>
                    <SingleQuestion mathJaxValue={question.question} attachment={question.attachmentUrl} />
                    <p className='question-label'>Answer</p>
                    <SingleQuestion mathJaxValue={question.key} />
                    <p className='question-label'>Alternate Answer</p>
                    <SingleQuestion mathJaxValue={question.alternateKey} />
                </>
            case QuestionTypeKeysWithAQ.PSGS:
            case QuestionTypeKeysWithAQ.PSGM:
                return <>
                    <p className='question-label'>Paragraph</p>
                    <SingleQuestion mathJaxValue={question.paragraph} attachment={question.paragraphUrl} />
                    <p className='question-label'>Questions</p>
                    <div className='ma-p10 ' style={{ background: 'inherit' }}>
                        {question.questions && question.questions.length ? <>
                            {question.questions.map((temp, index) => <>
                                <div className="">
                                    <div style={{ width: '60px', float: 'left' }}>
                                        {this.props.isCreateTest && <InputSwitch
                                            onChange={e => this.props.onSelectQuestionnew(temp, { checked: e.value }, question)}
                                            checked={this.props.selectedQIds.includes(temp.questionId)} />
                                        }
                                    </div>
                                    <div style={{ marginLeft: '60px' }}>
                                        <p className='question-label'>Question {index + 1}</p>
                                        <SingleQuestion mathJaxValue={temp.question} attachment={temp.attachmentUrl} />
                                        <div>
                                            {this.optionsRender(temp.options)}
                                        </div>
                                        <p className='ma-no-pm'> <span className='question-label'>Key</span><span className='question-d key-value'>
                                            {temp.questionType == QuestionTypeKeysWithAQ.PQS ? temp.key : (temp.questionType == QuestionTypeKeysWithAQ.PQM ? temp.key && temp.key.length && temp.key.join(', ') : '')}
                                        </span></p>
                                    </div>
                                </div>
                            </>)}
                        </> : <></>}
                    </div>
                </>

            case QuestionTypeKeysWithAQ.VSAQ:
            case QuestionTypeKeysWithAQ.SAQ:
            case QuestionTypeKeysWithAQ.LAQ:
                return <>
                    <p className='question-label'>Question</p>
                    <SingleQuestion mathJaxValue={question.question} attachment={question.attachmentUrl} />
                    <p className='question-label'>Key</p>
                    <SingleQuestion mathJaxValue={question.key} attachment={question.keyUrl} />
                    <p className='question-label'>Scheme of Validation</p>
                    <SingleQuestion mathJaxValue={question.schemeOfValidation} attachment={question.schemeOfValidationUrl} />
                </>
            default: return <></>
        }
    }




    render() {
        const { question } = this.props;
        return (
            <div className='question-in-tbl'>
                {
                    question && this.renderFullQuestion(question)
                }

            </div>
        )
    }
}
