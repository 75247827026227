import { BreadCrumb } from 'primereact/breadcrumb';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Toolbar } from 'primereact/toolbar';
import React, { Component } from 'react'
import { BasicLazyParams } from '../../../utile';
import LoadingComponent from '../../loadingComponent';
import { Toast } from 'primereact/toast';
import { Column } from 'primereact/column';
import { baseUrlAdmin } from '../../../store/apiConstants';
import Service from '../../../services';
import moment from 'moment/moment';
import { Badge } from 'primereact/badge';
import './styles.scss'


const items = [
    { label: 'Analytics' },
    { label: 'Proffesional Training' },
    { label: 'Course Management', className: 'ma-active-item' }
];

const home = { icon: 'pi pi-home' }

class ShowAssessmentsData extends Component {
    constructor(props) {
        super(props);
        this.state = {
            lazyParams: BasicLazyParams,
            isLoading: false,
            assessmentData: [],
            totalRecords: "",
            columns: []
        }
        this.service = new Service()
    }

    componentDidMount() {
        if (this.props.data && this.props.assessmentType) {
            this.getAssessmentsData()
        }
    }
    generateColumns = (data) => {
        if (data.length === 0 || !data[0].user_info || !data[0].user_info.leafKeys) return [];

        // Extract the unique levels from the leafKeys and create dynamic columns for each level
        const columns = data[0].user_info.leafKeys.map((key) => ({
            field: `level_${key.level}`, // Define field name for the column
            header: key.levelName, // Use `levelName` for column header
            body: (rowData) => {
                // Access the correct leafKey based on level and return the name
                const leafKey = rowData.user_info.leafKeys.find(item => item.level === key.level);
                return leafKey ? leafKey.name : "N/A";
            },
        }));

        this.setState({ columns });
    };

    getAssessmentsData = () => {
        let obj = {}
        obj.courseId = this.props.data?._id
        switch (this.props.assessmentType) {
            case "Prerequisite":
                obj.teacherTestType = "prerequisite"
                break
            case "Pre Assessment":
                obj.teacherTestType = "preAssessment"
                break
            case "Post Assessment":
                obj.teacherTestType = "postAssessment"
                break
            default:
                break
        }
        if (this.props.data?._id) {
            this.setState({ isLoading: true })
            let { lazyParams } = this.state;
            let url = `${baseUrlAdmin}/course/type?limit=${lazyParams.rows}&page=${lazyParams.page + 1}&search=`
            this.service.post(url, obj, true).then(res => {
                if (res?.status && res?.res?.status) {
                    this.setState({
                        isLoading: false,
                        assessmentData: res.res.data,
                        totalRecords: res.res.totalRecords
                    }, () => {
                        this.generateColumns(res.res.data);
                    })
                } else {
                    this.setState({ isLoading: false })
                    this.toast.show({ severity: 'error', summary: '', detail: res?.errMessage, life: 3000 });
                }
            }).catch(err => {
                console.log(err)
                this.setState({ isLoading: false })
                this.toast.show({ severity: 'error', summary: '', detail: err.errMessage, life: 3000 });
            })
        } else {
            this.toast.show({ severity: 'error', summary: '', detail: 'Error in ID', life: 3000 });
        }
    }

    onBackFromAssessments = () => {
        this.props.onBackFromAssessments()
    }

    rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                <h2 className='ma-tab-title'>Course Manegement</h2>
                {/* <p>{this.state.isShowChapters && 'Chapters'}</p> */}
            </React.Fragment>
        )
    }
    leftToolbarTemplate() {
        return (
            <React.Fragment>
                <BreadCrumb model={items} home={home} />
            </React.Fragment>
        )
    }
    onPageChangeUsers = (event) => {
        this.setState((prevState) => {
            return { lazyParams: { ...prevState?.lazyParams, ...event } };
        }, this.getAssessmentsData);
    }
    onPageChangeUsers = (event) => {
        this.setState((prevState) => {
            return { lazyParams: { ...prevState?.lazyParams, ...event } };
        }, this.getAssessmentsData);
    }
    formatStartDate = (rowData) => {
        console.log(rowData, "rowDatarowDatarowData")
        return moment(new Date(rowData.assessment
            .timeFrameStart)).format('DD-MMM-YY');
    };

    formatEndDate = (rowData) => {
        return moment(new Date(rowData.assessment
            .timeFrameEnd)).format('DD-MMM-YY');
    };


    testformatStartTime = (rowData) => {
        return moment(rowData.user_info.timeFrameStart).format('DD-MMM-YY hh:mm A');
    };

    testformatEndTime = (rowData) => {
        return moment(rowData.user_info.timeFrameEnd).format('DD-MMM-YY hh:mm A');
    };

    doPercent = (rowData) => {
        return rowData?.courses?.courseCompletionPercentage
            ? `${parseFloat(rowData.courses.courseCompletionPercentage.toFixed(2))}%`
            : "0.00%"; // Default to 0.00% if courseCompletionPercentage is undefined
    };


    doPercentUser = (rowData) => {
        return rowData?.courses?.userPercentage
            ? `${parseFloat(rowData.courses.userPercentage.toFixed(2))}%`
            : "0.00%"; // Default to 0.00% if courseCompletionPercentage is undefined
    };
    getHeadersAndLevels = (data) => {
        if (data.length === 0) return [];
        return data[0].user_info.leafKeys.map((key) => ({
            field: `level_${key.level}`, // Create unique field keys
            header: key.levelName, // Use `levelName` for header
            accessor: (rowData) => rowData.user_info.leafKeys.find((item) => item.level === key.level)?.name || "N/A",
        }));
    };
    render() {
        const { assessmentData, columns } = this.state;

        console.log(columns, "columnscolumns")
        return (
            <>
                <div className="p-grid ma-toolbar">
                    <div className="p-col-12 p-md-1 ma-no-p">
                    </div>
                    <div className="p-col-12 p-md-11 ma-no-pm">
                        <Toolbar className="ma-toolbard" left={this.leftToolbarTemplate} right={this.rightToolbarTemplate}></Toolbar>
                    </div>
                    <p className='back-arrow' onClick={this.onBackFromAssessments}><span><i className="pi pi-arrow-left"></i> </span> Back</p>
                </div>
                <div style={{ width: "100%", overflowX: "auto" }}> {/* Wrapper for horizontal scrolling */}
                    <DataTable ref={(el) => this.dt = el}
                        lazy
                        value={this.state.assessmentData}
                        dataKey="_id"
                        paginator
                        scrollable
                        onPage={this.onPageChangeUsers}
                        // onSort={this.onSortUsers}
                        first={this.state.lazyParams.first}
                        last={this.state.totalRecords}
                        rows={this.state.lazyParams.rows}
                        totalRecords={this.state.totalRecords}
                        rowsPerPageOptions={[5, 10, 25, 50, 100]}
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} tests"
                        globalFilter={this.state.globalFilter2}
                        header={<h2>{this.props.assessmentType}</h2>}
                    >

                        <Column field="user_info.username" header="User Name"  body={(rowData) => {
                            return (
                                
                                <>{rowData?.user_info?.username.slice(3)}</>
                            )
                        }}
                        style={{
                            width: "150px",
                            whiteSpace: "nowrap",
                            overflow: "hidden"
                        }}
                        />

                        

                        <Column field="user_info.given_name" header="Given Name" style={{
                            width: "150px",
                            whiteSpace: "nowrap",
                            overflow: "hidden"
                        }} />
                        {/* <Column field="user_info.email" header="Email ID" /> */}
                        {/* <Column field="user_info.custom:board" header="Board" /> */}
                        {/* <Column field="user_info.grade" header="Class" /> */}



                        {columns.map((col) => (
                            <Column
                                key={col.field}
                                field={col.field}
                                header={col.header}
                                body={col.body}
                                style={{
                                    width: "150px",
                                    whiteSpace: "nowrap",
                                    overflow: "hidden"
                                }}
                            />
                        ))}

                        <Column field="assessment.courseName" header="Course Name" style={{
                            width: "150px",
                            whiteSpace: "nowrap",
                            overflow: "hidden"
                        }} />
                        <Column field="courses.courseStartDate" header="Start Date" body={this.formatStartDate} style={{
                            width: "150px",
                            whiteSpace: "nowrap",
                            overflow: "hidden"
                        }} />
                        <Column field="courses.courseEndDate" header="End Date" body={this.formatEndDate} style={{
                            width: "150px",
                            whiteSpace: "nowrap",
                            overflow: "hidden"
                        }} />
                        <Column field="courses.courseCompletionPercentage" header="Completion%" body={this.doPercent} style={{
                            width: "150px",
                            whiteSpace: "nowrap",
                            overflow: "hidden"
                        }} />
                        <Column field="courses.courseCompletionStatus" header="Module status" style={{
                            width: "150px",
                            whiteSpace: "nowrap",
                            overflow: "hidden"
                        }} />
                        <Column field="assessment.examName" header="Test Name" style={{
                            width: "150px",
                            whiteSpace: "nowrap",
                            overflow: "hidden"
                        }} />
                        <Column field="assessment.timeFrameStart" header="Test Start Time" body={this.testformatStartTime} style={{
                            width: "150px",
                            whiteSpace: "nowrap",
                            overflow: "hidden"
                        }} />
                        <Column field="assessment.timeFrameEnd" header="Test End Time" body={this.testformatEndTime} style={{
                            width: "150px",
                            whiteSpace: "nowrap",
                            overflow: "hidden"
                        }} />
                        <Column field="courses.userPercentage" header="Score%" body={this.doPercentUser} style={{
                            width: "150px",
                            whiteSpace: "nowrap",
                            overflow: "hidden"
                        }} />
                        <Column field="assessment.lastResult" header="Result" style={{
                            width: "150px",
                            whiteSpace: "nowrap",
                            overflow: "hidden"
                        }} />
                        <Column field="assessment.noOfretakes" header="No of Retake" style={{
                            width: "150px",
                            whiteSpace: "nowrap",
                            overflow: "hidden"
                        }} />
                        <Column field="assessment.noOfQuestions" header="noOfQuestions" style={{
                            width: "150px",
                            whiteSpace: "nowrap",
                            overflow: "hidden"
                        }} />
                        <Column field="assessment.totalMarks" header="Total Marks" style={{
                            width: "150px",
                            whiteSpace: "nowrap",
                            overflow: "hidden"
                        }} />
                        <Column field="assessment.totalTime" header="Total Time" style={{
                            width: "150px",
                            whiteSpace: "nowrap",
                            overflow: "hidden"
                        }} />

                        <Column field="assessment.status" header="Status" body={(rowData) => {
                            return (
                                <Badge severity={rowData.assessment?.status === 'pending' ? "warning" : "info"} value={rowData.assessment?.status.toLowerCase()}></Badge>
                            )
                        }} style={{
                            width: "150px",
                            whiteSpace: "nowrap",
                            overflow: "hidden"
                        }} />
                        <Column field="" header="Submitted" body={(rowData) => {
                            let submittedDate = new Date(rowData.assessment.submittedAt)
                            return (
                                <>{rowData.assessment?.examStatus === "SUBMITTED" ? <p>{moment(submittedDate).format("DD-MM-YYYY HH:mm:ss")}</p> : "--"}</>
                            )
                        }} style={{
                            width: "150px",
                            whiteSpace: "nowrap",
                            overflow: "hidden"
                        }} />
                        <Column field="" header="Result" body={(rowData) => {
                            return (
                                <>{rowData.assessment?.status === "Completed" ? <p>{rowData.assessment?.totalMarksSecured}</p> : "--"}</>
                            )
                        }} style={{
                            width: "150px",
                            whiteSpace: "nowrap",
                            overflow: "hidden"
                        }} />
                    </DataTable>
                </div>
                <Toast ref={(el) => this.toast = el} position="bottom-right" />
                {this.state.isLoading && <LoadingComponent />}
            </>
        )
    }
}

export default ShowAssessmentsData